import gptLogo from './assets/chatgpt.svg';
import addBtn from './assets/add-30.png';
import msgIcon from './assets/message.svg';
import home from './assets/home.svg';
import saved from './assets/bookmark.svg';
import rocket from './assets/rocket.svg';
import sendBtn from './assets/send.svg';
import userIcon from './assets/user-icon.png';
import gptImgLogo from './assets/rocket.svg';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'
// import './App.css';

import { createMessageAndRun , createThread, getRun, getRunSteps} from './requests';

import { useState } from 'react';
import LoginSignup from './components/LoginSigup/LoginSignup';
import AuthContextProvider from './contexts/AuthContext';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard';
function App() {

  return (
    <div className="App">
          <Routes>
            <Route path='/' element={<LoginSignup />}
            />
            <Route path='/home' element={<Dashboard />}/>
          </Routes>
    </div>
  );
}

export default App;
