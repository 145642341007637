
import gptLogo from '../assets/chatgpt.svg';
import addBtn from '../assets/add-30.png';
import msgIcon from '../assets/message.svg';
import home from '../assets/home.svg';
import saved from '../assets/bookmark.svg';
import rocket from '../assets/rocket.svg';
import sendBtn from '../assets/send.svg';
import userIcon from '../assets/user-icon.png';
import gptImgLogo from '../assets/rocket.svg';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'
import './Dashboard.css';

import { createMessageAndRun , createThread, getRun, getRunSteps} from '../requests';

import { useEffect, useState } from 'react';
import { useLogout } from '../hooks/useLogout';

const Dashboard = () => {

  const {logout} = useLogout();
  const [loading, setLoading] = useState(false);

  const [input, setInput] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [file, setFile] = useState([]);

  const [messages, setMessages] = useState([
    {
      text: "Hi, I am dara bot. Ask me any question",
      isBot: true
    }
  ]);

  useEffect( () => {
        async function callCreateThread() {
            await createThread();
        }
        callCreateThread();
  }, []);
  const handleFileChange = (event) => {
    setFile(event.target.files[0])
    console.log(event.target.files);
    console.log(file);
  }

  const handleSend = async  () => {
      
     if (!input) {
        alert('Please enter a prompt')
      return false;
     
    }
      setMessages([...messages, {
        // text: res.content[0].text,
        text: input,
        isBot: false
      }]);

      setLoading(true);
      setDisabled(true);

      await createThread();
      const res = await createMessageAndRun(input, file);

      let interval = setInterval(async function() {
        let run = await getRun();

        if (run.status === 'completed') {
          
          clearInterval(interval);
          let newMsg = await getRunSteps();
          setMessages([...messages,
            {text: input, isBot: false}, {
            text: newMsg.content[0].text.value,
            isBot: true
          }]);

          setLoading(false);
          setDisabled(false);
          setInput('');
        }
      }, 10000);
  

  }
    return (
        <div className='home'>
            <div className="sideBar">
                <div className="upperSide">
                    <div className="upperSideTop">
                        <img src={gptImgLogo} alt="" className="logo" />
                        <span className="brand">dara bot</span>

                    </div>
                    {/* <div className="upperSideBottom">
            <button className="midBtn">
              <img src={addBtn} alt="" className="addBtn" />New Chat
            </button>
              <button className="query"><img src={msgIcon} alt="" className="" />What is programming</button>
              <button className="query"><img src={msgIcon} alt="" className="" />What is programming</button>
            </div> */}
                </div>
                <div className="lowerSide">
                    <div className="listItems"><img src={home} alt="" className="listItemsImg" onClick={logout} style={{cursor: 'pointer'}}/>Logout</div>
          {/* <div className="listItems"><img src={saved} alt="" className="listItemsImg" />Saved</div>
          <div className="listItems"><img src={rocket} alt="" className="listItemsImg" />Upgrade to Pro</div> */}

                </div>
            </div>

            <div className="main">

                <div className="chats">

                    <PerfectScrollbar color='#646477'>

                        {messages.map((message, i) => {
                            return (
                                <div key={i} className={message.isBot ? "chat bot" : "chat"}>
                                    <img src={message.isBot ? gptImgLogo : userIcon} alt="" className="chatImg" />{message.text}
                                    <br /><br /><br />
                                </div>

                            )
                        })}
                    </PerfectScrollbar>
                    <div style={{ paddingLeft: '5px' }}><FerrisWheelSpinner loading={loading} size={28} color='white' /> </div>

                </div>
                <div className="chatFooter">
                    <form onSubmit={handleSend}>
                        <div className="inp">
                            <input type="text" placeholder="Enter your prompt" value={input} onChange={(e) => { setInput(e.target.value) }} id='prompt' />
                            <button className="send" type='submit' disabled={disabled} style={{ float: 'right' }} onClick={handleSend}>Ask</button>
                        </div>
                        <br />

                        {/* <p>dara bot may prduce incorrect information </p> */}
                        <input type='file' accept='application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/csv,application/json,application/zip,application/xml,text/html' style={{ float: 'left', alignItems: 'start' }} onChange={handleFileChange} />

                    </form>
                </div>
                <br />
                {/* {file ? file.name : ''} */}
            </div>
        </div>
    )
}

export default Dashboard;