import axios from "axios";
import { useState } from "react"
import { useAuthContext } from "./useAuthContext";
import { useNavigate } from "react-router-dom";
// var baseUrl = 'http://dara-bot-api.test';
var baseUrl = 'https://bot-api.daravault.com';

export const useSignUp = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const {dispatch} = useAuthContext();
    const navigate = useNavigate();

    const signup = async (name, email, password, action) => {
        setIsLoading(true);
        setError(null);

        try {
            let data = {
                email,
                password
            }
            let route = '/api/login';

            if (action === 'Sign Up') {
                data.name = name;
                route = '/api/register';
            }
            const response = await axios.post(`${baseUrl}${route}`, data);

            if (response.status === 200) {
                setIsLoading(false);
                localStorage.setItem('user', JSON.stringify(response.data.user));
                localStorage.setItem('token', response.data.token);
                dispatch({type: "LOGIN", payload: response.data.user})
                
                navigate('/home');

            } else {
                setIsLoading(false);
                setError(response.data.message);
            }
        } catch(error) {
            setIsLoading(false);
            setError(error.response.data.message);
        }
    }

    return {signup, error, isLoading}
}