import axios from "axios";

var baseUrl = 'https://bot-api.daravault.com';
// var baseUrl = 'http://dara-bot-api.test';

export async function createThread() {

    if (!localStorage.getItem('thread_id')) {
        try {
            const res = await axios.post(`${baseUrl}/api/thread`);

            if (res.status == 200) {
                localStorage.setItem('thread_id', res.data.data.id);
            } 
            return res.data.data;
                       
        } catch(err) {
            console.log(err.response.data);
        }
    } else {
        return localStorage.getItem('thread_id');
    }
   
}

export async function createMessageAndRun(message, file) {
    let threadId = localStorage.getItem('thread_id');

    try {

        const formData = new FormData();
        formData.append('file', file )
        formData.append('role', 'user');
        formData.append('content', message);
        formData.append('thread_id', threadId);

        const res = await axios.post(`${baseUrl}/api/message`,formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (res.status == 200) {

            const threadRun = await axios.post(`${baseUrl}/api/thread-run`, {
                thread_id: threadId
            });

            if (threadRun.status == 200) {
                localStorage.setItem('run_id', threadRun.data.data.id);

                // return threadRun.data.data;
            }

            return  res.data.data;
        }
    }catch(err) {
        return err.response.data.message;
        console.log(err.response.data);
    }
}

export async function getRunSteps() {
    let thread_id = localStorage.getItem('thread_id');
    let run_id  = localStorage.getItem('run_id');

    try {
        const res = await axios.post(`${baseUrl}/api/thread-run-steps`, {
            thread_id,
            run_id
        });

        if (res.status == 200) {
            return res.data.data[0];
        }
    } catch (err) {
        console.log(err.response.data);
    }
}

export async function getRun() {
    let thread_id = localStorage.getItem('thread_id');
    let run_id  = localStorage.getItem('run_id');

    try {
        const res = await axios.post(`${baseUrl}/api/run-details`, {
            thread_id,
            run_id
        });

        if (res.status == 200) {
            return res.data.data;
        }
    } catch (err) {
        console.log(err.response.data);
    }
}