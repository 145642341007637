import user_icon from '../../assets/person.png'
import email_icon from '../../assets/email.png'
import password_icon from '../../assets/password.png'
import './LoginSignup.css';
import { useEffect, useState } from 'react';
import { useSignUp } from '../../hooks/useSignup';
import { FerrisWheelSpinner } from 'react-spinner-overlay';
import {  useNavigate } from 'react-router-dom';

const LoginSignup = () => {

    const navigate = useNavigate();
    const [action, setAction] = useState('Sign Up')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const {signup, error, isLoading} = useSignUp();

    const handleSignUp = async (e) => {

       await signup(name, email,password, action);
    
    }  
    
    useEffect(()=> {
        if (localStorage.getItem('token')) {
            navigate('/home');
        }
    }, [])



    return (
        <div>
            <div>
                <h2 style={{padding: '12px', fontSize: '30px'}}>Dara Bot</h2>
            </div>
            <div className="container">
                <div className="header">
                    <div className="text">
                        {action}
                    </div>
                    <div className="underline"></div>
                </div>
                <div className="inputs">
                <FerrisWheelSpinner loading={isLoading} size={28} color='white' />
                {error && <div style={{color: 'red'}} className='hav-account'>{error}</div>}
                {action === 'Sign Up' ?  <div className="input">
                
                        <img src={user_icon} alt="" />
                        <input type="text" placeholder='Name' onChange={(e) => setName(e.target.value)} value={name}/>
                    </div>: <div></div>}

                    <div className="input">
                        <img src={email_icon}alt="" />
                        <input type="email" placeholder='Email'  onChange={(e) => setEmail(e.target.value)} value={email}/>
                    </div>

                    <div className="input">
                        <img src={password_icon} alt="" />
                        <input type="password" placeholder='Password' onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                </div>
                {action === 'Sign Up' ? <div></div>: <div className="forgot-password">Forgot Password?<span>Click Here!</span></div>}
                {action === 'Sign Up'? <div className='hav-account'>Already have account?<span onClick={() => {setAction('Login')}}>Sign In</span></div>: <div className='new-account'>Dont Have account? <span onClick={() => setAction('Sign Up')}>Sign Up</span></div>}
                <div className="submit-container">
                    <div className={action === 'Login'?'submit': 'submit'} onClick={handleSignUp}>{action}</div>
                    {/* <div className={action === 'Sign Up'? 'submit gray': 'submit'} onClick={() => {setAction('Login')}}>Login</div> */}
                </div>
            </div>
        </div>
    )
}

export default LoginSignup;